body{
    background-color:#A6D684;  
    padding:0px;
    margin:0px;
}

.list{
    border-collapse: collapse;
    border:1px solid #cccccc;
    width:100%;
}

.list tr td{
    border-collapse: collapse;
    border:1px solid #cccccc;
    padding:10px;
    background-color:white;
    font-size: 16px;
    word-break: break-all;
}